import Router from '../utils/Router.js';
import Data from '../utils/Data.js';
import GalleryPage from './GalleryPage.js';
import InformationPage from './InformationPage.js';
import JournalPage from './JournalPage.js';
import { getState, setState } from '../utils/State.js';
import Packery from 'packery';
import ColorThief from 'colorthief';


const ProjectsPage = {
    pckryInstance: null,
    container: document.querySelector('.projects'),
    lastMouseX: window.innerWidth / 2,
    lastMouseY: window.innerHeight / 2,
    abortController: null,
    mouseMoved: false,
    isTouchDevice: ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) && window.innerWidth > 768,

    initialize(category = null) {
        this.filterProjects(category);
        this.renderFilters();
        this.setupMenuListeners();
        if(window.innerWidth > 768) {
            if(this.isTouchDevice) {
                document.body.classList.add('touch-device');
                document.body.classList.add('map-moved');
            } else {
                this.initializeMapMove();
            }
        } else {
            document.body.classList.add('map-moved');
            const filtersDiv = document.querySelector('.filters');
            const filterLink = document.querySelector('.filter-link');
        
            if (filterLink && filtersDiv && filterLink.nextElementSibling !== filtersDiv) {
                filterLink.parentNode.insertBefore(filtersDiv, filterLink.nextSibling);
            }
        }
        setTimeout(() => {
            if(ProjectsPage.mouseMoved === false) {
                document.body.classList.add('map-moved');
                ProjectsPage.mouseMoved = true;
            }
        }, 2000);
    },

    openProjectsPage() {
        InformationPage.closeInformationPage();
        JournalPage.closeJournalPage();
        GalleryPage.closeGalleryPage();
        ProjectsPage.fadeInShuffledThumbnails();
    },

    filterProjects(category) {
        const data = Data.getLimitedProjects(category);
        this.render(data);
    },

    openFilters() {
        document.body.classList.add('filters-open');
    },

    closeFilters() {
        document.body.classList.remove('filters-open');
    },

    setupMenuListeners() {
        document.querySelector('h1.site-title').addEventListener('click', (event) => {
            event.preventDefault();
            Router.navigate('');
        });

        document.querySelector('.menu-button').addEventListener('click', (event) => {
            event.preventDefault();
            
            document.body.classList.toggle('menu-open');
            document.body.classList.remove('filters-open');

        });

        if(window.innerWidth < 768) {
            document.querySelector('.menu').addEventListener('click', (event) => {
                if(event.currentTarget === event.target || event.target.classList.contains('filters')) {
                    event.preventDefault();
                    document.body.classList.remove('menu-open');
                    document.body.classList.remove('filters-open');
                }
            });
        }

        document.querySelector('.work-link').addEventListener('click', (event) => {
            event.preventDefault();
            Router.navigate('');
        });

        document.querySelector('.journal-link').addEventListener('click', (event) => {
            event.preventDefault();
            Router.navigate('journal');
        });

        document.querySelector('.information-link').addEventListener('click', (event) => {
            event.preventDefault();
            Router.navigate('information');
        });

        document.querySelector('.filter-link').addEventListener('click', (event) => {
            event.preventDefault();
            if(event.currentTarget.innerHTML === 'Filters') {
                this.openFilters();
            } else {
                const featuredButton = document.querySelector('.filters button[data-category="Featured"]');
                if (featuredButton) {
                    featuredButton.click();
                }
            }
        });
    },

    render(data) {
        const inner = document.querySelector('.projects-inner');
        inner.innerHTML = '';
        inner.style.width = '';
        inner.style.height = '';

        const shuffledData = [...data].sort(() => Math.random() - 0.5);
    
        setTimeout(() => {
            const minMargin = 40;
            const maxMargin = 100;
            const minMarginNegative = 50;
            const maxMarginNegative = 60;
    
            // Function to randomly decide if the value should be negative and generate the margin
            const maybeNegate = (minPos, maxPos, minNeg, maxNeg) => {
                if (Math.random() < 0.35) {
                    return -(Math.random() * (maxNeg - minNeg) + minNeg);
                } else {
                    return Math.random() * (maxPos - minPos) + minPos;
                }
            };
    
            // Generate random margins and possibly negate them
            const generateMargins = () => ({
                marginLeft: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`,
                marginTop: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`,
                marginRight: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`,
                marginBottom: `${maybeNegate(minMargin, maxMargin, minMarginNegative, maxMarginNegative)}px`
            });
    
            // Example usage:
            const margins = generateMargins();
            const isChrome = (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) || window.innerWidth < 768;
            const maxVideos = isChrome ? 15 : 2;
            let videoCount = 1;
    
            shuffledData.forEach((item, index) => {
                let isVideo;
                let mediaElement;
                let randomWidth;
                let randomHeight;
    
                const margins = generateMargins();
    
                if (item.thumbVideoUrl && videoCount++ < maxVideos) {
                    isVideo = true;
                    if(item.videoIsPortrait) {
                        randomHeight = this.biasedRandom(250, 400);
                        randomWidth = randomHeight * 0.5625;
                    } else {
                        randomWidth = this.biasedRandom(400, 600);
                        randomHeight = randomWidth * 0.5625;
                    }

                    mediaElement = `<video src="${item.thumbVideoUrl}" autoplay loop muted playsinline></video>`;
                    
                } else {
                    isVideo = false;
                    const min = 150;
                    const max = 350;

                    const thumbUrl = item.thumb ? item.thumb : item.poster;
                    
                    if(thumbUrl) {

                        if(item.ratio > 1) {
                            // Generate a biased random height
                            randomHeight = this.biasedRandom(min, max);
                            randomWidth = randomHeight * 1.25;
                        } else {
                            randomWidth = this.biasedRandom(min, max);
                            randomHeight = randomWidth * 1.25;
                        }
        
                        mediaElement = `<img src="${thumbUrl}" alt="${item.alt}">`;

                    } else {

                        mediaElement = `<img src="${document.body.dataset.basepath}/assets/img/noimage.jpg" alt="No thumbnail">`

                    }
                }
    
                if (mediaElement !== undefined) {
                    const thumbnailHTML = `
                        <a href="${item.project.path}" 
                        data-path="${item.project.path}" 
                        data-title="${item.project.title}" 
                        data-client="${item.project.client}" 
                        data-categories="${item.project.categories}" 
                        data-id="${item.id}" 
                        data-parallax="${Math.random() * 0.6}"
                        style="width: ${randomWidth}px; height: ${randomHeight}px; margin-left: ${margins.marginLeft}; margin-top: ${margins.marginTop}; margin-right: ${margins.marginRight}; margin-bottom: ${margins.marginBottom};"
                        class="loading-thumbnail thumbnail ${isVideo ? 'thumb-video' : 'thumb-image'}">
                            <div class="thumbnail-inner">
                                ${mediaElement}
                            </div>
                            <div class="thumbnail-title">
                                <span>${item.project.client ? item.project.client : item.project.title}</span>
                            </div>
                        </a>
                    `;
                    inner.innerHTML += thumbnailHTML;
                }
            });

            setTimeout(() => {
    
                // Initialize Packery after adding all thumbnails
                if (ProjectsPage.pckryInstance) {
                    ProjectsPage.pckryInstance.destroy();
                    ProjectsPage.pckryInstance = null;
                }
        
                // Initialize Packery after adding all thumbnails
                ProjectsPage.pckryInstance = new Packery(inner, {
                    itemSelector: '.thumbnail',
                    transformsEnabled: false,
                    transitionsEnabled: false,
                    transitionDuration: 0,
                });
        
                ProjectsPage.pckryInstance.layout();
    
                // pckry.layout();
        
                // Calculate the furthest edges
                const furthestLeft = Math.min(...Array.from(inner.children).map((child) => child.getBoundingClientRect().left));
                const furthestTop = Math.min(...Array.from(inner.children).map((child) => child.getBoundingClientRect().top));
                const furthestRight = Math.max(...Array.from(inner.children).map((child) => child.getBoundingClientRect().right));
                const furthestBottom = Math.max(...Array.from(inner.children).map((child) => child.getBoundingClientRect().bottom));
                // Set the width and height of the inner container to have an extra 100px on each side
                inner.style.width = `${furthestRight - furthestLeft + 200}px`;
                inner.style.height = `${furthestBottom - furthestTop + 200}px`;
                // move all the thumbnail absolute top and left positions inside to account for the new 100px on each side
                Array.from(inner.children).forEach((child) => {
                    child.style.left = `${parseInt(child.style.left) + 50}px`;
                    child.style.top = `${parseInt(child.style.top) + 50}px`;
                });

        
                // const mouseMoveEvent = new MouseEvent('mousemove', {
                //     clientX: this.lastMouseX,
                //     clientY: this.lastMouseY
                // });
                // document.dispatchEvent(mouseMoveEvent);
        
                // Call the fadeInShuffledThumbnails function if the gallery is not open
                if (getState().galleryOpen !== true) {
                    this.fadeInShuffledThumbnails();
                }
        
                this.setupProjectPageListeners(data);

            }, 10);
    
        }, 10);
    },

    fadeOutShuffledThumbnails(){
        ProjectsPage.container.querySelectorAll('a.thumbnail').forEach(item => {
            item.classList.add('loading-thumbnail');
        });
    },


    async fadeInShuffledThumbnails() {
        if (ProjectsPage.abortController) {
            ProjectsPage.abortController.abort();
        }
        ProjectsPage.abortController = new AbortController();
        const signal = ProjectsPage.abortController.signal;

        let lastLoadTime = Date.now();

        // Shuffle the thumbnails array
        const shuffledThumbnails = Array.from(document.querySelectorAll('.thumbnail')).sort(() => Math.random() - 0.5);

        const removeLoadingClass = async (item) => {
            const now = Date.now();
            const delay = Math.max(0, 50 - (now - lastLoadTime));
            lastLoadTime = now + delay;

            await new Promise(resolve => setTimeout(resolve, delay));

            // check it hasn't loaded in the time since we've opened the gallery
            if (getState().galleryOpen !== true) {
                if (item.classList.contains('has-fully-loaded')) {
                    item.classList.remove('loading-thumbnail');
                } else {
                    item.classList.remove('loading-thumbnail');
                    item.classList.add('has-fully-loaded');
                }
            }
        };

        try {
            for (const item of shuffledThumbnails) {
                if (signal.aborted) throw new Error('Aborted');

                item.classList.remove('thumbnail-clicked');

                const img = item.querySelector('img');
                const video = item.querySelector('video');

                if (img) {
                    // Remove existing event listener if it exists
                    if (img.loadEventHandler) {
                        img.removeEventListener('load', img.loadEventHandler);
                    }

                    // Define and attach new event handler
                    img.loadEventHandler = async function() {
                        if (signal.aborted) return;
                        await removeLoadingClass(item);
                        if (!img.getAttribute('data-hue')) {
                            const colorThief = new ColorThief();
                            const rgb = colorThief.getColor(img);
                            const hue = ProjectsPage.rgbToHue(rgb[0], rgb[1], rgb[2]);
                            item.setAttribute('data-hue', hue);
                        }
                    };

                    img.addEventListener('load', img.loadEventHandler);

                    // If the image is already loaded, trigger the load event handler manually
                    if (img.complete) {
                        img.dispatchEvent(new Event('load'));
                    }
                }

                if (video && getState().galleryOpen !== true) {
                    // Remove existing event listener if it exists
                    if (video.canplaythroughEventHandler) {
                        video.removeEventListener('loadeddata', video.canplaythroughEventHandler);
                    }

                    // Define and attach new event handler
                    video.canplaythroughEventHandler = async function() {
                        if (signal.aborted) return;
                        await removeLoadingClass(item);
                    };

                    video.addEventListener('loadeddata', video.canplaythroughEventHandler);
                }
            }
        } catch (error) {
            if (error.message !== 'Aborted') {
                console.error(error);
            }
        } finally {
            if (!signal.aborted) {
                ProjectsPage.abortController = null;
            }
        }
    },

    biasedRandom(min, max) {
        const range = max - min;
        const mid = (max + min) / 2;
        let rand = Math.random();
    
        // Bias towards the ends (quadratic distribution)
        rand = rand < 0.5 ? Math.pow(rand * 2, 2) / 2 : 1 - Math.pow((1 - rand) * 2, 2) / 2;
    
        return Math.floor(rand * range) + min;
    },

    setupProjectPageListeners(data) {
        // let siteTitleTimeout = setTimeout(showSiteTitle, 1000);
        let hoverTimeout;
        let hoverTimeoutInternal;
    
        // Function to manage showing the site title
        // function showSiteTitle() {
        //     if (!document.body.classList.contains('show-site-title')) {
        //         document.body.classList.add('show-site-title');
        //     }
        // }
    
        ProjectsPage.container.querySelectorAll('a.thumbnail').forEach(thumbnailLink => {

            thumbnailLink.addEventListener('click', (event) => {
                event.preventDefault();
    
                const clickedThumbnail = event.currentTarget;

                clickedThumbnail.classList.add('thumbnail-clicked');
    
                const path = thumbnailLink.getAttribute('data-path');
                const project = data.find(item => item.project.path === path).project;
                const mediaId = thumbnailLink.getAttribute('data-id');
                
                this.fadeOutShuffledThumbnails();

                const timeout = window.innerWidth > 768 ? 500 : 200;

                setTimeout(() => {
                    // Store all projects and clicked media info in state
                    setState({ allProjects: data.map(item => item.project), currentProject: project, galleryOpen: true, currentMediaId: mediaId });
                    Router.navigate(path);
                }, timeout);
            });
    
            thumbnailLink.addEventListener("mouseenter", function(e) {
                clearTimeout(hoverTimeout);
                clearTimeout(hoverTimeoutInternal);
                // clearTimeout(siteTitleTimeout); // Prevent site title from showing while hovering
    
                // Ensure only one title is shown at a time
                
    
                hoverTimeout = setTimeout(() => {

                    // document.body.classList.remove('show-site-title');
                    document.body.classList.remove('show-sub-title');

                    hoverTimeoutInternal = setTimeout(() => {
                        const projectClient = thumbnailLink.getAttribute('data-client');
                        const projectTitle = thumbnailLink.getAttribute('data-title');
                        // const combinedTitle = projectClient ? `${projectTitle} <span>for</span> ${projectClient}` : projectTitle;
                        const combinedTitle = projectClient ? projectClient : projectTitle;

                        document.querySelector('.sub-title').innerHTML = combinedTitle;

                        thumbnailLink.classList.add('hovering-thumbnail');
                        document.body.classList.add('hovering-a-thumbnail');
        
                        document.body.classList.add('show-sub-title');
        
                        const hue = thumbnailLink.getAttribute('data-hue');
                        
                        const bgContainer = document.querySelector('.bg-colour-container');
                        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                            // dark mode
                        }
                        const lightness = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 12 : 92;

                        if(hue) {
                            bgContainer.style.backgroundColor = 'hsl(' + hue + ', 30%, ' + lightness + '%)';
                        } else {
                            bgContainer.style.backgroundColor = '';
                        }

                    }, 200);
    
                }, 500);
            });
    
            thumbnailLink.addEventListener("mouseleave", function(e) {
                clearTimeout(hoverTimeout); // Clear the hover timeout
                clearTimeout(hoverTimeoutInternal);
    
                // Start timeout to potentially add 'show-site-title'
                // siteTitleTimeout = setTimeout(showSiteTitle, 500); // 1000ms to wait before showing site title
    
                thumbnailLink.classList.remove('hovering-thumbnail'); // Remove hover styles
                document.body.classList.remove('hovering-a-thumbnail');

                if(!e.target.classList.contains('thumbnail-clicked')) {
                    const bgContainer = document.querySelector('.bg-colour-container');
                    bgContainer.style.backgroundColor = '';
                }
    
                document.body.classList.remove('show-sub-title');
            });
        });
    },

    renderFilters(activeCategory = 'Featured') {
        const container = document.querySelector('.filters');
        container.innerHTML = `
            ${Data.siteData.site.projectCategories.map(category => `
                <button data-category="${category.categoryName}" class="${category.categoryName === activeCategory ? 'active-category' : ''}"><span>${category.categoryName}</span></button>
            `).join('')}
        `;
    
        container.querySelectorAll('button').forEach(button => {
            const timeout = window.innerWidth > 768 ? 500 : 200;

            button.addEventListener('click', (event) => {
                const category = button.getAttribute('data-category');
                this.fadeOutShuffledThumbnails();
                setTimeout(() => {
                    this.filterProjects(category);
                    const activeButton = container.querySelector('button.active-category');
                    if (activeButton) activeButton.classList.remove('active-category');
                    button.classList.add('active-category');
                    if (button.dataset.category === 'Featured') {
                        document.querySelector('.filter-link').innerHTML = 'Filters';
                    } else {
                        document.querySelector('.filter-link').innerHTML = '<span>×</span> Filtered by ' + button.dataset.category;
                    }
                }, timeout);
            });
        });
    
        let closeFiltersTimeout;
    
        container.addEventListener('mouseleave', () => {
            closeFiltersTimeout = setTimeout(() => {
                this.closeFilters();
            }, 500);
        });

        if(ProjectsPage.isTouchDevice) {
            container.addEventListener('click', (event) => {
                event.preventDefault();
                this.closeFilters();
            });
        }
    
        container.addEventListener('mouseenter', () => {
            clearTimeout(closeFiltersTimeout);
        });
    },

    initializeMapMove() {
        const container = document.querySelector('.projects');
        const containerInner = document.querySelector('.projects-inner');
    
        let targetTranslateX = 0, targetTranslateY = 0;
        const decayRate = 0.075;
    
        let mouseX = 0, mouseY = 0;
    
        let currentTranslateX = 0, currentTranslateY = 0;
    
        const visibleThumbnails = new Set();
    
        // Initialize IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    visibleThumbnails.add(entry.target);
                } else {
                    visibleThumbnails.delete(entry.target);
                }
            });
        }, { threshold: [0] });
    
        const observeThumbnails = () => {
            document.querySelectorAll('.thumbnail').forEach((rect) => {
                observer.observe(rect);
            });
        };
    
        // MutationObserver to detect changes in .projects-inner
        const mutationObserver = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    // Observe new thumbnails
                    mutation.addedNodes.forEach(node => {
                        if (node.classList && node.classList.contains('thumbnail')) {
                            observer.observe(node);
                        }
                    });
    
                    // Unobserve removed thumbnails
                    mutation.removedNodes.forEach(node => {
                        if (node.classList && node.classList.contains('thumbnail')) {
                            observer.unobserve(node);
                            visibleThumbnails.delete(node);
                        }
                    });
                }
            });
        });
    
        mutationObserver.observe(containerInner, { childList: true, subtree: true });
    
        
        
        document.addEventListener('mousemove', function(e) {
        
            mouseX = e.clientX;
            mouseY = e.clientY;

            ProjectsPage.lastMouseX = mouseX;
            ProjectsPage.lastMouseY = mouseY;
    
            const percentX = mouseX / window.innerWidth;
            const percentY = mouseY / window.innerHeight;
    
            targetTranslateX = percentX * (containerInner.scrollWidth - container.clientWidth);
            targetTranslateY = percentY * (containerInner.scrollHeight - container.clientHeight);
            if (ProjectsPage.mouseMoved === false) {
                // Set initial position immediately without transition
                currentTranslateX = targetTranslateX;
                currentTranslateY = targetTranslateY;
                applyTransform();
                document.body.classList.add('map-moved');
                ProjectsPage.mouseMoved = true;
            }
        
        });
    
        function applyDamping(target, current, totalLength) {
            const edgeThreshold = 50;
            const minDampingFactor = 0.1;
            const maxDampingFactor = 1;
            let dampingFactor = maxDampingFactor;
    
            const distanceFromStart = current;
            const distanceFromEnd = totalLength - current;
    
            if (distanceFromStart < edgeThreshold) {
                dampingFactor = minDampingFactor + (distanceFromStart / edgeThreshold) * (maxDampingFactor - minDampingFactor);
            } else if (distanceFromEnd < edgeThreshold) {
                dampingFactor = minDampingFactor + (distanceFromEnd / edgeThreshold) * (maxDampingFactor - minDampingFactor);
            }
    
            return (target - current) * decayRate * dampingFactor;
        }
    
        const ticker = () => {
            const deltaX = applyDamping(targetTranslateX, currentTranslateX, containerInner.scrollWidth);
            const deltaY = applyDamping(targetTranslateY, currentTranslateY, containerInner.scrollHeight);
    
            currentTranslateX += deltaX;
            currentTranslateY += deltaY;
    
            applyTransform();
            updateRectangleAppearance(); // Ensure this function is called every frame
            requestAnimationFrame(ticker);
        };
    
        function applyTransform() {
            // Ensure the translation values are within bounds
            currentTranslateX = Math.min(Math.max(currentTranslateX, 0), containerInner.scrollWidth - container.clientWidth);
            currentTranslateY = Math.min(Math.max(currentTranslateY, 0), containerInner.scrollHeight - container.clientHeight);
    
            containerInner.style.transform = `translate3d(${-currentTranslateX}px, ${-currentTranslateY}px, 0)`;
        }
    
        function updateRectangleAppearance() {
            visibleThumbnails.forEach((rect) => {
                const rectInner = rect.querySelector('.thumbnail-inner');
                const parallaxFactor = parseFloat(rect.dataset.parallax) || 0;
    
                const rectBounds = rect.getBoundingClientRect();
                const rectCenterX = rectBounds.left + rectBounds.width / 2;
                const rectCenterY = rectBounds.top + rectBounds.height / 2;
                const distanceX = mouseX - rectCenterX;
                const distanceY = mouseY - rectCenterY;
    
                const maxDistanceX = window.innerWidth / 2;
                const maxDistanceY = window.innerHeight / 2;
    
                const translateX = -(distanceX / maxDistanceX) * parallaxFactor * 100;
                const translateY = -(distanceY / maxDistanceY) * parallaxFactor * 100;
    
                // Calculate 3D transform
                const maxDistance = Math.sqrt(window.innerWidth * window.innerWidth + window.innerHeight * window.innerHeight);
                const distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));
                const translateZ = Math.sqrt(Math.max(0, maxDistance * maxDistance - distance * distance)) * parallaxFactor * -10;
    
                rectInner.style.transform = `translate(${translateX}px, ${translateY}px) translateZ(${translateZ}px)`;
    
                // Calculate opacity and scale based on distance
                const scale = 1 - (distance / maxDistance) * 0.5;
                const opacity = 1 - (distance / maxDistance) * 1.2;
    
                rect.style.transform = `scale(${scale})`;
                rect.style.opacity = Math.max(opacity, 0.01);
            });
        }
    
        ticker(); // Start the ticker
    
        window.addEventListener('resize', () => {
            // Recalculate positions and dimensions
            const percentX = mouseX / window.innerWidth;
            const percentY = mouseY / window.innerHeight;
    
            targetTranslateX = percentX * (containerInner.scrollWidth - container.clientWidth);
            targetTranslateY = percentY * (containerInner.scrollHeight - container.clientHeight);
    
            currentTranslateX = targetTranslateX;
            currentTranslateY = targetTranslateY;
            applyTransform();
        });
    
        // Initial observation of thumbnails
        observeThumbnails();
    },

    rgbToHue(r, g, b) {
		r /= 255;
		g /= 255;
		b /= 255;
	
		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let hue;
		const delta = max - min;
	
		if (delta === 0) { // This is a gray, no chroma
			hue = 0; // Hue is technically undefined, set it to 0
		} else {
			switch (max) {
				case r: // Red is max
					hue = ((g - b) / delta) % 6;
					break;
				case g: // Green is max
					hue = (b - r) / delta + 2;
					break;
				case b: // Blue is max
					hue = (r - g) / delta + 4;
					break;
			}
	
			hue *= 60; // Convert to degrees on the color wheel
			if (hue < 0) hue += 360; // Make sure hue is not negative
		}
	
		return hue; // Returns hue value (0 - 360)
	}
};

export default ProjectsPage;